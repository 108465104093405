/*=============== GOOGLE FONTS ===============*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
@import '../styles/theme.scss';

/*========== Responsive typography ==========*/
@media screen and (min-width: 1024px) {
    :root {
        --normal-font-size: 1rem;
    }
}

/*=============== BASE ===============*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: $body-font;
    font-size: $normal-font-size;
    background-color: $body-color;
}

ul {
    list-style: none;
    /* Color highlighting when pressed on mobile devices */
    /*-webkit-tap-highlight-color: transparent;*/
}

a {
    text-decoration: none;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container {
    max-width: 1120px;
    margin-inline: 1.5rem;
}

/*=============== HEADER ===============*/
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $black-color;
    // box-shadow: 0 2px 16px hsla(220, 32%, 8%, 0.3);
    z-index: $z-fixed;
}

/*=============== NAV ===============*/
.nav {
    height: $header-height;
}

.nav__logo,
.nav__burger,
.nav__close {
    color: $font-color;
}

.nav__data {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__logo {
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    font-weight: $font-semi-bold;
    font-size: 2rem;
    cursor: pointer;
    /* Color highlighting when pressed on mobile devices */
    /*-webkit-tap-highlight-color: transparent;*/
}

.nav__logo i {
    font-weight: initial;
}

.nav__toggle,.show-icon {
    position: relative;
    width: 32px;
    height: 32px;
}

.nav__burger,
.nav__close {
    position: absolute;
    width: max-content;
    height: max-content;
    inset: 0;
    margin: auto;
    font-size: 1.25rem;
    cursor: pointer;
    transition: opacity 0.1s, transform 0.4s;
}

.nav__close {
    opacity: 0;
}

/* Navigation for mobile devices */
@media screen and (max-width: 1118px) {
    .nav__menu {
        position: absolute;
        left: 0;
        top: 2.5rem;
        width: 100%;
        height: calc(100vh - 3.5rem);
        overflow: auto;
        pointer-events: none;
        opacity: 0;
        transition: top 0.4s, opacity 0.3s;
    }
    .nav__menu::-webkit-scrollbar {
        width: 0;
    }
    .nav__list {
        background-color: $black-color;
        padding-top: 1rem;
    }
}

.nav__link {
    color: $font-color;
    background-color: $black-color;
    font-weight: $font-semi-bold;
    padding: 1.25rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s;
    cursor: pointer;
}

.nav__link:hover {
    background-color: $black-color-light;
}

/* Show menu */
.show-menu {
    opacity: 1;
    top: 3.5rem;
    pointer-events: initial;
}

/* Show icon */
.show-icon .nav__burger {
    opacity: 0;
    transform: rotate(90deg);
}
.show-icon .nav__close {
    opacity: 1;
    transform: rotate(90deg);
}

/*=============== DROPDOWN ===============*/
.dropdown__item {
    cursor: pointer;
}

.dropdown__arrow {
    font-size: 1.25rem;
    font-weight: initial;
    transition: transform 0.4s;
}

.dropdown__link,
.dropdown__sublink {
    padding: 1.25rem 1.25rem 1.25rem 2.5rem;
    color: $font-color;
    background-color: $black-color-light;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    font-weight: $font-regular;
    transition: background-color 0.3s;
}

.dropdown__link i,
.dropdown__sublink i {
    font-size: 1.25rem;
    font-weight: initial;
}

.dropdown__link:hover,
.dropdown__sublink:hover {
    background-color: $black-color;
}

.dropdown__menu,
.dropdown__submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
}

/* Show dropdown menu & submenu */
.dropdown__item:hover .dropdown__menu,
.dropdown__subitem:hover > .dropdown__submenu {
    max-height: 1000px;
    transition: max-height 0.4s ease-in;
}

/* Rotate dropdown icon */
.dropdown__item:hover .dropdown__arrow {
    transform: rotate(180deg);
}

/*=============== DROPDOWN SUBMENU ===============*/
.dropdown__add {
    margin-left: auto;
}

.dropdown__sublink {
    background-color: $black-color-lighten;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 340px) {
    .container {
        margin-inline: 1rem;
    }

    .nav__link {
        padding-inline: 1rem;
    }
}

@media screen and (max-width: 798px) {
    .footer {
        background-color: $black-color;
        width: 100%;
        height: auto !important;
        padding: 32px 32px 12px 32px;
        color: $font-color;
        .footerMenus {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            a {
                color: rgb(23, 23, 23);
                &:hover {
                    text-decoration: underline;
                }
            }
            .menu {
                width: 100%;
                display: flex;
                flex-direction: column;
                h2 {
                    font-size: 1rem;
                    padding: 12px 0px;
                }
                h4 {
                    padding: 4px 0px;
                }
                svg{
                    cursor: pointer;
                }
            }
        }
        .copyright {
            padding: 18px 0px;
        }
    }
}

/* For large devices */
@media screen and (min-width: 1118px) {
    .container {
        // margin-inline: auto;
        margin-left: 3.5rem;
    }

    .nav {
        height: calc($header-height) + 2rem;
        display: flex;
        justify-content: space-between;
    }
    .nav__toggle {
        display: none;
    }
    .nav__list {
        height: 100%;
        display: flex;
        column-gap: 3rem;
    }
    .nav__link {
        height: 100%;
        padding: 0;
        justify-content: initial;
        column-gap: 0.25rem;
    }
    .nav__link:hover {
        background-color: transparent;
    }

    .dropdown__item,
    .dropdown__subitem {
        position: relative;
    }

    .dropdown__menu,
    .dropdown__submenu {
        max-height: initial;
        overflow: initial;
        position: absolute;
        left: 0;
        top: 6rem;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s, top 0.3s;
    }

    .dropdown__link,
    .dropdown__sublink {
        padding-inline: 1rem 1rem;
        width: 210px;
    }

    .dropdown__subitem .dropdown__link {
        padding-inline: 1rem;
    }

    .dropdown__submenu {
        position: absolute;
        left: 100%;
        top: 0.5rem;
    }

    /* Show dropdown menu */
    .dropdown__item:hover .dropdown__menu {
        opacity: 1;
        top: 5.5rem;
        pointer-events: initial;
        transition: top 0.3s;
    }

    /* Show dropdown submenu */
    .dropdown__subitem:hover > .dropdown__submenu {
        opacity: 1;
        top: 0;
        pointer-events: initial;
        transition: top 0.3s;
    }
}

.footer {
    background-color: $black-color;
    height: 300px;
    width: 100%;
    padding: 32px 32px 12px 32px;
    color: $font-color;
    .footerMenus {
        width: 100%;
        height: 80%;
        display: flex;
        justify-content: center;
        a {
            color: rgb(23, 23, 23);
            &:hover {
                text-decoration: underline;
            }
        }
        .menu {
            width: 30%;
            display: flex;
            flex-direction: column;
            h2 {
                padding: 12px 0px;
            }
            h4 {
                padding: 4px 0px;
            }
            svg{
                cursor: pointer;
            }
            div {
                cursor: pointer;
                color: rgb(23, 23, 23);
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .copyright {
        padding: 18px 0px;
    }
}
