$header-height: 3.5rem;
$black-color: #fdfefe;
$font-color: #114a29;
$black-color-light: rgba(245, 245, 245, 0.893);
$black-color-lighten: rgba(245, 245, 245, 0.766);
$white-color: #ffffff;
$body-color: hsl(0, 0%, 96%);
$body-font: 'Poppins', sans-serif;
$normal-font-size: 0.938rem;
$font-regular: 400;
$font-semi-bold: 600;
$z-tooltip: 10;
$z-fixed: 100;
// #004293
// #102542
// $body-color: hsl(220, 100%, 97%);
// $font-color: #111d4a;
// $black-color-light: hsl(220, 24%, 15%);
// $black-color-lighten: hsl(220, 20%, 18%);