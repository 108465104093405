.contact{
    margin-top: 88px;
    padding:32px;
    &__header{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 150px;
        gap: 12px;
        h1{
            color: #031404;
        }
        h4{
            font-weight: 400;
            color: rgb(81, 81, 81);
        }
    }
    &__contacts{
        background-color: white;
        padding: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 32px;
        &-card{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border: 1px solid rgb(194, 194, 194);
            border-radius: 12px;
            height: 130px;
            width: 225px;
            min-width: 180px;
            padding: 12px;
            &-title{
                display: flex;
                gap: 12px;
                align-items: center;
                h6{
                    font-size: 16px;
                    font-weight: 400;
                    color: #676767;
                }
            }
            &-icon{
                display: flex;
                height: 40px;
                width: 40px;
                align-items: center;
                justify-content: center;
                border: 1px solid rgb(209, 209, 209);
                color: #0d3a11;
                border-radius: 6px;
            }
            &-detail{
                color: #364538;
                font-size: 14px;
                font-weight: 500;
                overflow: hidden;
                text-wrap: wrap;
                a {
                    color: rgb(23, 23, 23);
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &__location{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap:32px;
        padding:32px;
        justify-content: center;
        align-items: center;
        background-color: white;
        iframe{
            width: 100%;
        }
        h2{
            font-weight: 500;
        }
        h4{
            font-weight: 400;
        }
        &-address{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap:8px;
            width: 30%;
            text-align: center;
            color: #0d3a11;
            h5{
                font-weight: 400;
            }
        }
    }
}